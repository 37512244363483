<template>
  <ClientOnly>
  <Teleport to="body">
    <!-- 1. Trolley preview Drawer & Modal -->
    <template v-if="isMobile">
      <TsDrawer
        data-testid=""
        v-model:visible="trolleyStore.preview_overlay_visible"
        position="bottom"
        append-class="bg-transparent overflow-hidden"
      >
        <TsModalAddToTrolley
          :collection-availability="trolleyStore.collection_availability"
          @set-branch="handleClickOnSetBranch"
          :selected-variant-label="
            trolleyStore.previewed_product.pack_size ||
            trolleyStore.previewed_product.name
          "
          v-if="trolleyStore.previewed_product"
          :product="trolleyStore.previewed_product"
          data-testid=""
          @add-to-trolley="(q: number, c: number) => handleAddToTrolleyAction(q, c)"
          @close="trolleyStore.preview_overlay_visible = false"
          @click-variants="
            trolleyStore.getProductVariants(
              trolleyStore.previewed_product.variations
            )
          "
        />
      </TsDrawer>
    </template>
    <template v-if="isDesktop">
      <TsModal
        v-model:visible="trolleyStore.preview_overlay_visible"
        append-class="max-h-[80vh] bg-transparent overflow-hidden"
      >
        <template #modal-content>
          <TsModalAddToTrolley
            :collection-availability="trolleyStore.collection_availability"
            @set-branch="handleClickOnSetBranch"
            :selected-variant-label="
              trolleyStore.previewed_product.pack_size ||
              trolleyStore.previewed_product.name
            "
            v-if="trolleyStore.previewed_product"
            :product="trolleyStore.previewed_product"
            data-testid=""
            @add-to-trolley="(q: number, c: number) => handleAddToTrolleyAction(q, c)"
            @close="trolleyStore.preview_overlay_visible = false"
            @click-variants="
              trolleyStore.getProductVariants(
                trolleyStore.previewed_product.variations
              )
            "
          />
        </template>
      </TsModal>
    </template>

    <!-- 2. Product Variants Drawer & Modal -->
    <template v-if="isMobile">
      <TsDrawer
        data-testid=""
        v-model:visible="trolleyStore.product_variants_modal_visible"
        position="bottom"
        append-class="max-h-[60vh] bg-transparent overflow-hidden"
      >
        <TsModalProductVariants
          :image="trolleyStore.previewed_product?.image"
          :brand="trolleyStore.previewed_product?.brand"
          :name="trolleyStore.previewed_product?.full_name"
          :channel="trolleyStore.previewed_product_selected_channel"
          @notify=""
          @select="(selectedVariant) => handleVariantSelection(selectedVariant)"
          :variants="trolleyStore.previewed_product_variations"
          @close="trolleyStore.product_variants_modal_visible = false"
          data-testid=""
        />
      </TsDrawer>
    </template>

    <template v-if="isDesktop">
      <TsModal
        v-model:visible="trolleyStore.product_variants_modal_visible"
        append-class="max-h-[80vh] max-w-[450px]"
      >
        <template #modal-content>
          <TsModalProductVariants
            :image="trolleyStore.previewed_product?.image"
            :brand="trolleyStore.previewed_product?.brand"
            :name="trolleyStore.previewed_product?.full_name"
            @notify=""
            @select="
              (selectedVariant) => handleVariantSelection(selectedVariant)
            "
            :variants="trolleyStore.previewed_product_variations"
            @close="trolleyStore.product_variants_modal_visible = false"
            data-testid=""
          />
        </template>
      </TsModal>
    </template>

    <!-- 3. Trolley confirmation modal -->
    <TsModalTrolleyItemConfirmation
      append-class="overflow-y-scroll"
      v-model:visible="trolleyStore.confirmation_overlay_visible"
      data-testid="trolley-add-ons-recommendations"
      :product="trolleyStore.previewed_product"
      @go-to-trolley="handleTrolleyPageNavigation"
      @hide="trolleyStore.confirmation_overlay_visible = false"
    />

    <!-- TODO: remove this code and watch branch selection via trolleyStore.listen -->
    <!-- <TsModalBranchSelector
      id="listing-page-branch-selector"
      data-testid="listing-page-branch-selector"
      v-model:visible="listingPageBranchSelectorModalVisible"
      @save="handleBranchSave"
    /> -->

  </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { Product } from "~/types/ecom/product/product.type";

const { isMobile, isDesktop } = useDevice();

const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();

const listingPageBranchSelectorModalVisible = ref(false);

// watch for a single product
watch(
  () => [branchStore.is_branch_set, trolleyStore.previewed_product],
  async () => {
    if (!branchStore.is_branch_set || !trolleyStore.previewed_product) return;

    trolleyStore.collection_stock_loading = true;

    trolleyStore.collection_availability =
      await trolleyStore.evaluateCollectionAvailability(
        trolleyStore.previewed_product
      );

    trolleyStore.collection_stock_loading = false;
  }
);

async function handleAddToTrolleyAction(quantity: number, channel: number) {
  // Direct ship delivery
  if (trolleyStore.previewed_product?.direct_ship) {
    trolleyStore.addToTrolleyForDirectship(
      trolleyStore.previewed_product.code,
      quantity
    );
    // IN HURRY 
    setTimeout(() => {
      trolleyStore.preview_overlay_visible = false;
      trolleyStore.confirmation_overlay_visible = true;
    }, 2000);
    return;
  }

  // Next day collection
  if (trolleyStore.collection_availability?.status === "NextDayCollection") {
    trolleyStore.addToTrolleyForNextDayCollection(
      trolleyStore.previewed_product!.code,
      quantity
    );
    setTimeout(() => {
      trolleyStore.preview_overlay_visible = false;
      trolleyStore.confirmation_overlay_visible = true;
    }, 2000);
    return;
  }

  // Collection
  if (trolleyStore.previewed_product_selected_channel === 2) {
    await trolleyStore.addToTrolleyForCollection(
      trolleyStore.previewed_product!.code,
      quantity
    );
    setTimeout(() => {
      trolleyStore.preview_overlay_visible = false;
      trolleyStore.confirmation_overlay_visible = true;
    }, 2000);
    return;
  }

  // Delivery
  await trolleyStore.addToTrolleyForDelivery(
    trolleyStore.previewed_product!.code,
    quantity
  );
  setTimeout(() => {
    trolleyStore.preview_overlay_visible = false;
    trolleyStore.confirmation_overlay_visible = true;
  }, 2000);
}

async function handleTrolleyPageNavigation() {
  trolleyStore.confirmation_overlay_visible = false;
  await navigateTo("/trolley");
}

function handleVariantSelection(selectedVariant: Product) {
  trolleyStore.previewed_product = selectedVariant;
  trolleyStore.product_variants_modal_visible = false;
  trolleyStore.preview_overlay_visible = true;
}

function handleClickOnSetBranch() {
  trolleyStore.preview_overlay_visible = false;
  listingPageBranchSelectorModalVisible.value = true;
}

async function handleBranchSave() {
  listingPageBranchSelectorModalVisible.value = false;
  trolleyStore.preview_overlay_visible = true;
}

// necessary to avoid any stale modal opening after a lot of subsequent trolley additions
onUnmounted(() => {
  trolleyStore.confirmation_overlay_visible = false;
});
</script>
